<template>
  <div class="modal fade" id="modalGrafDash" tabindex="-1" aria-hidden="true" style="z-index: 950 !important;" >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content modal-xl ">
        <div class="row">
            <div class="col-11 d-flex justify-content-center align-items-center">
              <div style="margin-top: 113px;width: 350px;height: 55px;background: #A2DCC4 0% 0% no-repeat padding-box;box-shadow: 0px 3px 6px #00000029;border-radius: 8px;opacity: 1;">
                  <span class="d-flex justify-content-center align-items-center" style="margin-top: 14px;text-align: left;font: normal normal bold 30px/16px Open Sans;letter-spacing: 0.75px;color: #FFFFFF;opacity: 1;">
                    Atendidos vs. Meta
                  </span>
              </div>
            </div>
            <div class="col-1 d-flex justify-content-center" style="margin-top: 12px;">
              <button @click="cerrar()" style="z-index: 999;width: 29px; height: 29px; background: #EBEBEB 0% 0% no-repeat padding-box; opacity: 1; border-radius: 50%;" type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
        </div>
        <div class="modal-body row" style="margin-left: 83px;width: 880px!important;height: 190px !important;">
          <div class="grafica1 col" style="width: 600px !important; height: 400px !important;">
            <div  v-if="cargando" style="height: 190px;  display: grid; place-items: center;">
              <span class="spinner-border spinner-border-sm " role="cargando" aria-hidden="true"></span>
            </div>
            <Chart v-if="!cargando" type="bar" :data="chartData" :options="options" ref="chartRef" />
          </div>
          <!--  <graficas :dataPoints="fechasYDatos" /> -->
        </div>
        
      </div>
      
    </div>
  </div>
</template>

<script>
import asesorService from "../../../src/services/asesor"
import Chart from 'primevue/chart';

export default {
  components: {
    'Chart': Chart
  },
  props: {
    fechaInicial: {
      type: String,
      required: true,
    },
    fechaFinal: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cargando: true,
      metaTotal: 370,
      asesor: JSON.parse(sessionStorage.getItem('sesion')),
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Pacientes Atendidos',
            data: [],
            backgroundColor: '#ffcc00',
          },
          {
            label: 'Meta Total',
            data: [],
            backgroundColor: '#63b598',
          },
        ],
      },
      options: {
        responsive: true,
        hoverMode: 'index',
        scales: {
          yAxes: [{
            stacked: false,
            ticks: {
              beginAtZero: true,
            },
          }],
          xAxes: [{
            stacked: true,
            barThickness: 30,
            barPercentage: 0.8,
          }],
        },
        legend: {
          display: true,
        },
        tooltips: {
          enabled: true,
        },
      },
    };
  },
  mounted(){
    // Llamar al método asesorespordia cuando el componente se monte
    this.asesorespordia();
  },
  methods: {
    resetChartData() {
    this.chartData = {
      labels: [],
      datasets: [
        {
          label: 'Pacientes Atendidos',
          data: [],
          backgroundColor: '#ffcc00',
        },
        {
          label: 'Meta Total',
          data: [],
          backgroundColor: '#63b598',
        },
      ],
    };
  },
  asesorespordia() {
    let id = this.asesor.id;
    let fechai = this.fechaInicial;
    let fechaf = this.fechaFinal;
    asesorService.dashboarAsesorespordia(id, fechai, fechaf).then(resp => {
      this.chartData.labels = resp.map(item => item.createdAt);
      this.chartData.datasets[0].data = resp.map(item => item.count);
      this.chartData.datasets[1].data = Array(resp.length).fill(this.metaTotal);
      this.cargando = false;
    }).catch(error => {
      console.error("Se produjo un error durante la ejecución de asesorService.dashboarAsesorespordia:", error);
    });
  },
    cerrar() {
    document.querySelector('[data-target="#modalGrafDash"]').click(close);
    this.resetChartData();
    this.cargando=true
  },
  },
  watch: {
    fechaInicial: 'asesorespordia', 
    fechaFinal: 'asesorespordia',   
  },
};
</script>


<style scoped>
</style>
