<template>
  <div class="emotion-selector row row-cols-5" style="margin-top: 25px;">
    <div
      v-for="emotion in emotions"
      :key="emotion.id"
      @click="selectEmotion(emotion.id)"
      style="margin-right: 23px;"
      :class="{ 'emotion-icon col-1': true, selected: selectedEmotion === emotion.id }"
    >
      <img
        :id="'emocion'+emotion.id"
        :src="emotion.image"
        :alt="emotion.name"
        :style="{ width: '58px', height: '58px' }"
        :class="{ 'selected-img': selectedEmotion === emotion.id }"
      />
      <p id="labelDAFel" :class="'emocion'+emotion.id" :style="{ color: selectedEmotion === emotion.id ? '#0074D9' : ' #545E66' }">{{ emotion.name }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      emotions: [
        { id: 1, name: "Motivad@", image: require("../../assets/images/DashBoardAsesor/Icono - Motivad@.png") },
        { id: 2, name: "Feliz", image: require("../../assets/images/DashBoardAsesor/Icono - Feliz.png") },
        { id: 3, name: "Normal", image: require("../../assets/images/DashBoardAsesor/Icono - Normal.png") },
        { id: 4, name: "Triste", image: require("../../assets/images/DashBoardAsesor/icono - Triste.png") },
        { id: 5, name: "Estresad@", image: require("../../assets/images/DashBoardAsesor/Icono - estresad@.png") },
      ],
      selectedEmotion: null,
    };
  },
  methods: {
    selectEmotion(emotionId) {
      this.selectedEmotion = emotionId;
      this.$emit('emotionSelected', emotionId); 
    },
  },
};
</script>

<style scoped>
.emotion-selector {
  display: flex;
  justify-content: center;
}

.emotion-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}

.selected-img {
  border: 2px solid #0074D9;
  border-radius: 10px;
}

.selected p {
  color: #0074D9;
}
#labelDAFel{
  text-align: left;
font: normal normal 600 14px/25px Open Sans;
letter-spacing: 0px;
opacity: 1;
}
#emocion1{

  top:123px;
  left:46px
}
#emocion2{

  top:123px;
  left:143px
}
#emocion3{

  top:123px;
  left:240px
}
#emocion4{

  top:123px;
  left:338px
}
#emocion5{

  top:123px;
  left:435px
}
.emocion1{

  top:192px;
  left:44px
}
.emocion2{

  top:192px;
  left:160px
}
.emocion3{

  top:192px;
  left:245px
}
.emocion4{

  top:192px;
  left:348px
}
.emocion5{

  top:192px;
  left:429px
}
</style>