<template>
    <div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap">
        
        <div class="container-fluid">
            <div class="row">
                <div class="col" style="height:101px;">
                    <Navbar @dato-enviado-al-padre="recibirDato" />
                </div>
            </div>

            <div class="row">
                    <div class="col-sm-4" style="margin-top: 75px;">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-5 d-flex justify-content-center align-items-center">
                                <td><img class="rounded-circle imgPerfilDAsesor" :src="'https://sd-storage.sfo2.digitaloceanspaces.com/PLATAFORMA-INBOX/imagenes/asesores/'+asesor.id+'.jpg'" :alt="asesor.nombre" height="159.49px" width="159.49px" style="object-fit: cover"></td>
                            </div>
                            <div class="col-sm-5">
                                <div class="nombreDAsesor">{{ asesor.nombre }}</div>
                                <span class="metaDAsesor">{{ this.meta }} de 288 | <i class="icon-whatsapp h5 text-whatsapp"></i> Chats de WhatsApp</span>
                                <estrellas class="estrellas" :rating="this.calificacion"/>            
                                <!-- <span class="evaluacion">Tu evaluación ya está lista</span>   -->
                            </div>
                        </div>
                        <!-- <span class="pdf"><font-awesome-icon icon="download" /> Descargar PDF </span> --> 
                    </div>
                    <div class="monitoreoT col-sm-7" style="margin-left: 55px;margin-top: 29px; ">
                        <header class="monitoreo row">
                            <span class="tituloMon col-md-auto mr-auto">Monitoreo</span>
                            <div class=" col-auto">
                                <img class="logoWaDa" src="../assets/images/DashBoardAsesor/Icono - Monitoreo.png" alt="Logo de whatsapp para Salud Digna">
                            </div>
                        </header>
                        <div class="row row-cols-4 ">
                            <div class="col-3">
                                <div class="form-group " style="">
                                    <label for="fecha_ini">Fecha inicio</label>
                                    <input v-model="fecha_ini" type="date" class="form-control" style="max-width: 200px; height: 40px;" @input="sumarF(fecha_ini,1)"/>
                                </div>
                            </div>
                            
                            <div class="form-group col-3 " style="">
                                <label for="fecha_fin">Fecha final</label>
                                <input v-model="fecha_fin" type="date" class="form-control" style="max-width: 200px; height: 40px;" disabled/>
                            </div>
                            <div class="col-3 col-md-2"  style="margin-top: 27px;">
                                <button type="button" class="btn btn-primary" style="width: 100%;max-width: 200px;" :disabled="!this.fecha_ini" @click="filtro">Buscar</button>
                            </div>
                            <div class="col-3 col-md-4" style="padding: 0; text-align: center;">
                                <div style="margin-top: 42px;">
                                    <span  data-toggle="modal" data-target="#modalConverDash" style="left: 202px;top:24px; width: 230px; height: 22px; text-decoration: underline; font: normal normal bold 16px/23px Open Sans; letter-spacing: 0px; color: #0074D9; opacity: 1; cursor: pointer;">Ver todas las conversaciones</span>
                                </div>
                                
                            </div >
                           
                        </div>
                        <div class="data-table-wrapper" ref="dataTable">
                            <table id="tablaDA" class="row" style="    max-height: 117px;">
                                <thead class="col-12" style="font: normal normal bold 18px/23px Open Sans;">
                                    <tr class="row">
                                        <th class="col-2">Número de teléfono</th>
                                        <th class="col-3">Calificación</th>
                                        <th class="col-2">Motivo</th>
                                        <th class="col-4">Comentario</th>
                                        <th class="col-1"></th>
                                        </tr>
                                </thead>
                                <tbody class="col-12" style="font: normal normal normal 16px/23px Open Sans;">
                                        <tr class="row" v-for="notaesp in NotasEsp" :key="notaesp.id">
                                            <td class="col-2 d-flex justify-content-center align-items-center">{{ notaesp.telefono }}</td> 
                                            <td class="col-3 d-flex align-items-center"><estrellas :rating="(notaesp.calificacion)"/></td> 
                                            <td class="col-2 d-flex justify-content-center align-items-center" style="font: normal normal normal 16px/23px Open Sans;letter-spacing: 0px;color: #E53355;">{{ notaesp.desmotivo }}</td> 
                                            <td class="col-4 d-flex justify-content-center align-items-center">{{ notaesp.descripcion }} </td>
                                            <tb class="col-1 d-flex justify-content-center align-items-center"><a @click="iconClicked(notaesp)" style="cursor: pointer;"><font-awesome-icon icon="eye" style="color: #0074D9; opacity: 1;" /></a></tb>
                                        </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row row-cols-2 justify-content-center" style="margin-top: 20px">
                    <div class="felizometroDAsesor col-5">
                        <div class="headerFelDAse row row-cols-2">
                            <div class="col-xl-11 col-md-10">
                                <div style="margin-top: 3.5px;margin-left: 16px;">
                                    <span style="left: 31px;top: 3.5px;width: 279px;height: 24px;text-align: left;font: normal normal bold 18px/23px Open Sans;letter-spacing: 0px;color: #FFFFFF;opacity: 1;">¿Cómo te sientes el día de hoy?</span>
                                    <br>
                                    <span style="left: 31px;top: 27px;width: 397px;height: 40px;text-align: left;font: normal normal normal 16px/18px Open Sans;letter-spacing: 0px;color: #FFFFFF;opacity: 1;">Para ingresar al chat, favor de responder la encuesta del felizómetro.</span>
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-center align-items-center">
                                <img style="top:11px;left: 467px;" src="../assets/images/DashBoardAsesor/Icono - ¿Cómo te sientes el día de hoy.png">
                            </div>
                            
                        </div>
                        <div>
                            <EmotionSelector :emotions="emotions" @emotionSelected="handleEmotionSelected" />
                            <div class="row justify-content-center" style="margin-top: 16px;">
                                <input class="col " type="text" v-model="DAfeli" placeholder="Si tienes algun comentario, puedes colocarlo aquí." style="max-width: 475px;height: 35px;border: 1px solid #AFAFAF;border-radius: 8px;opacity: 1;">
                            </div>
                            <div class="row justify-content-center" style="margin-top: 20px;">
                                <button v-show="!selectedEmotion"  type="button" class="btn btn-secondary col" disabled style="max-width: 250px;height: 40px;border-radius: 4px;opacity: 1;"><font-awesome-icon :icon="['far', 'comment']" /> Acceder al chat</button>
                                <button v-show="selectedEmotion"  @click="envioFelizometro(asesor.id, selectedEmotion, DAfeli, entradaTurno)" type="button" class="btn btn-primary col" style="max-width: 250px;height: 40px;border-radius: 4px;opacity: 1;"><font-awesome-icon :icon="['far', 'comment']" /> Acceder al chat</button>
                            </div>
                            
                        </div>
                    </div> 
                    <div class="col-1"></div>
                    <div class="pacienteQuincenaDAsesor col-5">
                        <div class="headerpacienteQuincenaDAse row">
                            <div class="col-xl-11 col-md-10" style="margin-top: 23px;">
                                <span style="margin-left: 4px;max-width: 317px;height: 24px;text-align: left;font: normal normal bold 18px/23px Open Sans;letter-spacing: 0px;color: #FFFFFF;opacity: 1;">Pacientes atendidos en la quincena</span>
                            </div>
                            <div class="col-1 d-flex justify-content-center align-items-center">
                                <img  src="../assets/images/DashBoardAsesor/Icono - Pacientes atendidos en la quincena.png">
                            </div>
                            
                        </div>
                        <div class="row row-cols-2">
                            <div class="col-6 ">
                                <div class="d-flex justify-content-center align-items-center" style="margin-top: 10px;">
                                    <span style="top: 80px;left: 17px;width: 179px;height: 24px;text-align: left;font: normal normal bold 18px/17px Open Sans;letter-spacing: 0.45px;color: #616161;opacity: 1;">Atendidos vs. Meta</span>
                                </div>
                                <div style="margin-top:13px">
                                    <Grafica  :height="180" :fecha="diaAc" :numeroTotalMeta="totalMetaEnDashboardA" :numeroMiMeta="this.meta"/>
                                </div>
                                
                            </div>
                            <div class="row col-6">
                                    <div class="col" >
                                            <label for="fecha_iniAA">Fecha inicio</label>
                                            <input v-model="fecha_iniAA" type="date" id="fecha_iniAA" class="form-control" @input="sumarF(fecha_iniAA,2)"/>
                                    </div>
                                    <div class="col" >
                                        <label for="fecha_finAA">Fecha final</label>
                                        <input v-model="fecha_finAA" type="date" id="fecha_finAA" class="form-control" disabled/>
                                    </div>
                                    <div class="col d-flex justify-content-center align-items-center">
                                        <button  data-toggle="modal" data-target="#modalGrafDash" style="top: 215px;left: 277px;width: 200px;height: 40px;" type="button" class="btn btn-primary" :disabled="!fecha_finAA || !fecha_iniAA">Buscar</button>
                                    </div>
                                
                                <div class="row">
                                    <div class="col">
                                        <!--<span style="top: 147px;left: 186px;width: 21px;height: 17px;text-align: left;font: normal normal bold 12px/16px Open Sans;letter-spacing: 0.3px;color: #616161;opacity: 1; ">{{ numeroTotalMeta }}</span>
                                        <span style="top: 175px;left: 186px;width: 21px;height: 17px;text-align: left;font: normal normal bold 12px/16px Open Sans;letter-spacing: 0.3px;color: #616161;opacity: 1; ">{{ this.metaAA }}</span> -->
                                        <div class="col">
                                            <span style="top: 274px;left: 275px;"><font-awesome-icon style="width: 7px;height: 7px;color: #17A76C;opacity: 1;" icon="circle" /></span>
                                            <span style="top: 280px;left: 285px;height: 17px;text-align: left;font: normal normal normal 12px/12px Open Sans;letter-spacing: 0.3px;color: #616161;opacity: 1;">Pacientes que se deben atender</span>
                                        </div>
                                        <div class="col">
                                            <span style="top: 299px;left: 275px;"><font-awesome-icon style="width: 7px;height: 7px;color: #FFC549 ;opacity: 1;" icon="circle" /></span>
                                            <span style="top: 302px;left: 285px;height: 17px;text-align: left;font: normal normal normal 12px/17px Open Sans;letter-spacing: 0.3px;color: #616161;opacity: 1;">Pacientes atendidos</span>
                                        </div>
                                    </div>
                                </div>      
                            </div>
                        </div>
                        </div>
                </div>
                <!-- <div class="row">
                    <div class="col-1"></div>
                    <div class="rankingDAsesor col-10" style="margin-top: 20px;">
                        <div class="row">
                            <div class="col headerrankingDAsesor">
                                <div class="row">
                                    <div class="col-xl-11" style="margin-top: 23px;">
                                        <span style="text-align: left;font: normal normal bold 18px/23px Open Sans;letter-spacing: 0px;color: #FFFFFF;opacity: 1;">Ranking del {{this.dias }} de {{ this.mes }}</span>
                                    </div>
                                    <div class="col-1 d-flex justify-content-center align-items-center" style="margin-top: 8px;">
                                        <img style="width: 41px;height: 56px;" src="../assets/images/DashBoardAsesor/Icono - Ranking.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row" style="height: 70%;">
                            <div class="col d-flex justify-content-center align-items-center">
                                <ranking :names="nameList" :largo="largo" style="width: 100%;"/>
                            </div>
                        </div>
                    </div>
                </div> -->
                    
                    <div>
                        <!-- <div class="headerCalendarioDAsesor">
                            <span></span>
                        </div>  -->
                        <!-- <div class="calendarioDAsesor">
                            <calendario :current-date="currentDate" :jsonData="jsonDatos" />
                            <img style="position: absolute;top:14px;left: 522px;" src="../assets/images/DashBoardAsesor/Icono - Calendario.png">
                            <span style="position: absolute; top: 598px;left: 19px;width: 85px;height: 24px;text-align: left;font: normal normal bold 18px/23px Open Sans;letter-spacing: 0px;color: #616161;opacity: 1;">Etiquetas</span>
                            <span style="position:absolute;top: 594px;left: 124px;"><font-awesome-icon style="width: 10px;height: 10px;color: #A4E533;opacity: 1;" icon="circle" /> Visitas</span>
                            <span style="position:absolute;top: 615px;left: 124px;"><font-awesome-icon style="width: 10px;height: 10px;color: #FFB71B;opacity: 1;" icon="circle" /> Notas</span>
                            <span style="position:absolute;top: 594px;left: 223px;"><font-awesome-icon style="width: 10px;height: 10px;color: #33C0E5;opacity: 1;" icon="circle" /> Checklist</span>
                            <span style="position:absolute;top: 615px;left: 223px;"><font-awesome-icon style="width: 10px;height: 10px;color: #E14598;opacity: 1;" icon="circle" /> Vacaciones</span>
                            <span style="position:absolute;top: 594px;left: 334px;"><font-awesome-icon style="width: 10px;height: 10px;color: #E53355;opacity: 1;" icon="circle" /> Dia feriado</span>
                            <span style="position:absolute;top: 615px;left: 334px;"><font-awesome-icon style="width: 10px;height: 10px;color: #C05AFB;opacity: 1;" icon="circle" /> Permiso</span>
                            <span style="position:absolute;top: 594px;left: 481px;"><font-awesome-icon style="width: 10px;height: 10px;color: #FF851B;opacity: 1;" icon="circle" /> Meet</span>
                            <span style="position:absolute;top: 615px;left: 481px;"><font-awesome-icon style="width: 10px;height: 10px;color: #29A793;opacity: 1;" icon="circle" /> Evento</span>
                        </div>  -->
                    </div> 
                
            
        </div>
        <Dialog class="conversaciones" :closable="false" :modal="true" :header="'El Monitor ['+this.monitor+'] envio una Nota para el numero +'+this.telefono+' con motivo de '+this.motivo" :visible.sync="chat" style="z-index:9999">
            <Mensajes style="z-index:1051 !important"/>
            <template #footer>
                <Button class="btn btn-danger btn-sm" @click="cerrar">
                    <font-awesome-icon class="mr-1" icon="times" /><span class="ocultoMovil">Cerrar conversacion</span>
                </Button>
                <Button class="btn btn-success btn-sm" @click="cerrarNota()">
                  <font-awesome-icon class="mr-1" icon="times" /><span class="ocultoMovil"> Cerrar Nota</span>
             </Button>
            </template>
        </Dialog>
        <div class="modal fade" id="modalConverDash" tabindex="-1" aria-hidden="true" style="z-index: 950 !important;" >
  <div class="modal-dialog modal-dialog-centered modal-custom-width">
    <div class="modal-content modal-xl">
        <div class="row" style="margin-top: 20px;">
            <div class="col-11 d-flex justify-content-center align-items-center">
                <div  style="width: 350px;height: 55px;background: #A2DCC4 0% 0% no-repeat padding-box;box-shadow: 0px 3px 6px #00000029;border-radius: 8px;opacity: 1;">
                    <span class="d-flex justify-content-center align-items-center" style="height: 41px;text-align: left;font: normal normal bold 30px/16px Open Sans;letter-spacing: 0.75px;color: #FFFFFF;opacity: 1;">Conversaciones</span>
                </div>
            </div>
            <div class="col-1 d-flex justify-content-center align-items-center">
                <button  @click="cerrarModal" style="z-index: 999; width: 29px; height: 29px; background: #EBEBEB 0% 0% no-repeat padding-box; opacity: 1; border-radius: 50%;" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
      <div class="modal-body">
        <div class="data-table-wrapper" ref="dataTable">
                            <table id="tablaDA" class="row">
                                <thead class="col-12" style="font: normal normal bold 18px/23px Open Sans;">
                                    <tr class="row">
                                        <th class="col-2">Número de teléfono</th>
                                        <th class="col-3">Calificación</th>
                                        <th class="col-2">Motivo</th>
                                        <th class="col-3">Comentario</th>
                                        <th class="col-1"></th>
                                        </tr>
                                </thead>
                                <tbody class="col-12" style="font: normal normal normal 16px/23px Open Sans;">
                                        <tr class="row" v-for="nota in Notas" :key="nota.id">
                                            <td class="col-2 d-flex justify-content-center align-items-center">{{ nota.telefono }}</td> 
                                            <td class="col-3 col-3 d-flex align-items-center "><estrellas :rating="(nota.calificacion)"/></td> 
                                            <td class="col-2 d-flex justify-content-center align-items-center" style="font: normal normal normal 16px/23px Open Sans;letter-spacing: 0px;color: #E53355;">{{ nota.desmotivo }}</td> 
                                            <td class="col-3 d-flex justify-content-center align-items-center">{{ nota.descripcion }} </td>
                                            <tb class="col-1 d-flex justify-content-center align-items-center"><a @click="iconClicked(nota)" style="cursor: pointer;"><font-awesome-icon icon="eye" style="color: #0074D9; opacity: 1;" /></a></tb>
                                        </tr>
                                </tbody>
                            </table>
                        </div>
      </div>
    </div>
  </div>
</div>
    <!-- Modal Graficas -->
    <graficas :fechaInicial="this.fecha_iniAA"  :fechaFinal="this.fecha_finAA" :meta="288"/>
    </div>
    </template>
    <style>
    .modal-body .data-table-wrapper {
    max-height: 55vh; /* Ajusta la altura máxima según tus preferencias */
    overflow-y: auto; /* Agrega una barra de desplazamiento vertical si es necesario */
  }
.modal-xl{
top: 370px;
width: 1153px;
height: 65vh;
  z-index: 10 !important;
}
.imgPerfilDAsesor{
    top: 177px;
    left: 82.29px;
    width: 159px;
    height: 159px;
    border: 4px solid #FFFFFF;
    opacity: 1;
    /* position: absolute; */
}
.nombreDAsesor{
    top: 199px;
    left: 258px;
    height: 50px;
    text-align: left;
    font: normal normal bold 20px/23px 'Open Sans';
    letter-spacing: 0px;
    color: #616161;
    opacity: 1;
    /* position: absolute; */
}
.metaDAsesor{
   /*  position: absolute; */
    text-align: left;
    top: 257px;
    left: 258px;
height: 22px;
font: normal normal normal 16px/23px Open Sans;
letter-spacing: 0px;
color: #616161;
opacity: 1;
}
.estrellas{
    top: 294px;
left: 258px;
width: 239px;
height: 35px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 8px;
opacity: 1;
/* position: absolute; */
}
.evaluacion{
    /* position: absolute; */
    top: 376px;
left: 258px;
width: 209px;
    height: 22px;
    
    text-align: left;
    font: normal normal bold 16px/23px Open Sans;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}
.pdf{
    position: absolute;
    top: 375px;
left: 510px;
width: 152px;
height: 20px;
/* UI Properties */
text-align: left;
text-decoration: underline;
font: normal normal normal 15px/25px Open Sans;
letter-spacing: 0px;
color: #0074D9;
opacity: 1;
}
.monitoreo{
height: 69px;
background: #18A76C 0% 0% no-repeat padding-box;
opacity: 1;
}
.tituloMon{
 
    top: 25px;
left: 23px;
width: 94px;
height: 24px;
text-align: left;
font: normal normal bold 18px/23px Open Sans;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
}
.logoWaDa{
  
    margin-top: 12px;
left: 1040px;
width: 47px;
height: 45px;
opacity: 1;
}
.monitoreoT{
   
width: 1112px;
height: 270px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 16px;
opacity: 1;
}
#fecha_fin{
    top: 21px;
    position: absolute;
}
#fecha_ini{
    top: 21px;
    position: absolute;
}
#tablaDA{
    text-align: center;

width: 1083px;
letter-spacing: 0px;
color: #545454;
opacity: 1; 
}
.data-table-wrapper {
    overflow: auto;
}
.felizometroDAsesor{
height: 346px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 16px;
opacity: 1;
}
.pacienteQuincenaDAsesor{
height: 346px;
/* UI Properties */
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 16px;
opacity: 1;
}
.rankingDAsesor{
height: 274px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 16px;
opacity: 1;
}
.calendarioDAsesor{
    position: absolute;
    top: 418px;
left: 1163px;
width: 591px;
height: 640px;
/* UI Properties */
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border-radius: 0px 0px 16px 16px;
opacity: 1;
}
.headerCalendarioDAsesor{
    position: absolute;
    top: 418px;
left: 1163px;
width: 591px;
height: 70px;

background: #17A76C 0% 0% no-repeat padding-box;
opacity: 1;
}
.headerFelDAse{
height: 70px;
background: #18A76C 0% 0% no-repeat padding-box;
opacity: 1;
}
.headerpacienteQuincenaDAse{
height: 70px;
background: #18A76C 0% 0% no-repeat padding-box;
opacity: 1;
}
.headerrankingDAsesor{
height: 70px;
background: #18A76C 0% 0% no-repeat padding-box;
opacity: 1;
}
.selected-emotion {
  border: 2px solid #0074D9;
  /* Estilos adicionales para la sección de emoción seleccionada */
}
.grafica1{
    height: 100%;
}
</style>
    <script>
// @ is an alias to /src
import Vue from 'vue';
import asesorService from "../services/asesor"
import Navbar from '@/components/Navbar.vue'
/* import Menu from '@/components/Dashboard/Menu.vue' */
import SocketIo from 'socket.io-client'
import Estrellas from '../components/Dashboard/Estrellas.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye,faCircle,faDownload,faComment} from '@fortawesome/free-solid-svg-icons'
library.add(faEye,faCircle,faDownload,faComment)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Dialog from "primevue/dialog";
import Mensajes from "../components/Mensajes.vue";
import 'simplebar/dist/simplebar.min.css';
import SimpleBar from 'simplebar';
import EmotionSelector  from '../components/Dashboard/EmotionSelector.vue'
import Grafica  from '@/components/Dashboard/Grafica.vue'
import moment from 'moment';
//import Ranking from '../components/Dashboard/Ranking.vue'
import reportesServices from "../services/reportes"
//import Calendario from '../components/Dashboard/Calendario.vue'
import Graficas  from '../components/Dashboard/Graficas.vue'
    export default {
        name: 'dashboard',
        props: {
            //fecha: Date,
            reportes: String,
        },
        components: {
            Navbar,
            /* Menu, */
            Estrellas,
            FontAwesomeIcon,
            Dialog,
            Mensajes,
            EmotionSelector,
            Grafica,
            //Ranking,
            //Calendario,
            Graficas
        },
        data() {
            return {
                nameList:[],
                layout: 'resumen',
                asesor: JSON.parse(sessionStorage.getItem('sesion')),  
                socket: null,
                meta:0,
                calificacion:0,
                fecha_ini:null,
                fecha_fin:null,
                fecha_iniAA:null,
                fecha_finAA:null,
                Notas:null,
                chat:false,
                telefono:null,
                nombre:null,
                motivo:null,
                monitor:null,
                dias:'',
                idestatus:null,
                selectedEmotion: null,
                entradaTurno:1,
                reporte: '',
                fecha:null,
                metaAA:null,
                diasMetaAA:null,
                numeroTotalMeta:288,
                mes: "",
                fechaIni: '',
                fechaFin: '',
                listaAse:null,
                repote:null,
                largo:null,
                jsonDatos: [],
                currentDate: new Date(),
                fechasYDatos: [],
                diaAc:null,
                totalMetaEnDashboardA:null,
                datoRecibido:'',
                conve:null,
                NotasEsp:null
            }
        },
        mounted(){ 
            this.formatDate()
            //this.listaAsesores()
            this.validadUsuario();
            this.get()
            this.getNotasAsesor()
            new SimpleBar(this.$refs.dataTable);
            //this.checkMonthHalf()
        },
        methods: {
            recibirDato(dato) {
            this.totalMetaEnDashboardA = dato;
            },
             validadUsuario : function(){ 
                 let asesor1 = JSON.parse(sessionStorage.getItem('sesion'))   
                 if(!asesor1){
                     window.location.href ='/'
                 }
                 
                 /* if(this.asesor.rol_id == 3){
                     window.location.href ='/chat'
                 } */
    
                 if(asesor1.grupo_id==6 && window.location.pathname!='/dashboard/parametros'){
                    window.location.href ='/dashboard/parametros'
                 }
            },
        get(){
            let data = {
                query: {
                    "Tipo": "1"
                }
            }
            var socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
            socket.on('monitorAsesor',  (resp) => {
            this.socket = resp.filter(item => item.asesor_id ==  this.asesor.id );
            //socket.emit('updateNumeroMiMeta', this.numeroMiMeta);
            this.meta= this.socket[0].pacientes_whatsapp
            this.porcentaje(this.socket[0].porcentaje_calificacion)
            this.metaAA=this.meta
            })
        },
        porcentaje(decimalValue) {
            if (decimalValue == 0){
                this.calificacion = 0;
            }else if (decimalValue < 15) {
                this.calificacion = 1;
            } else if (decimalValue < 25) {
                this.calificacion = 2;
            } else if (decimalValue < 35) {
                this.calificacion = 3;
            } else if (decimalValue < 45) {
                this.calificacion = 4;
            } else if (decimalValue < 55) {
                this.calificacion = 5;
            } else if (decimalValue < 65) {
                this.calificacion = 6;
            } else if (decimalValue < 75) {
                this.calificacion = 7;
            } else if (decimalValue < 85) {
                this.calificacion = 8;
            } else if (decimalValue < 95) {
                this.calificacion = 9;
            } else {
                this.calificacion = 10;
            }
        },
        getNotasAsesor(){
            let dato={
                asesorid:this.asesor.id 
            } 
            asesorService.getNota(dato).then(resp => {
                if(resp.length>0){
                this.Notas = resp;
                this.NotasEsp=resp;
                }
            });
        },
        iconClicked(data) {
            this.conve=data
            this.chat = true;
            this.telefono = data.telefono;
            this.monitor=data.nombre_monitor
            this.motivo = data.desmotivo
            sessionStorage.setItem('sender_actual',data.telefono)  
                this.$bus.$emit("abrirConversacion")
                this.$bus.$emit('monitorear', 4);
                this.$bus.$emit('ver-mensajes', data.telefono)
        },
        cerrar(){
            this.chat=false
        },
        handleEmotionSelected(emotion) {
            this.selectedEmotion = emotion;
        // Aquí puedes manejar la emoción seleccionada en el componente "Dashboard"
        },
        async envioFelizometro(a,b,c,d){
            let res = await asesorService.estatusFelizometro(a,b,c,d)
            window.location.href = '/chat';
                if(!res.status){
                alert('Error al enviar la encuesta, intentelo nuevamente')  
                return  
                }
            this.entradaTurno=0
        }, 
        checkMonthHalf() {
            moment.locale('es');
            const currentDate = moment();
            const dayOfMonth = currentDate.date();
            const daysInMonth = currentDate.daysInMonth();
            if (dayOfMonth <= Math.floor(daysInMonth / 2)) {
                this.mes = this.capitalizeFirstLetter(currentDate.format('MMMM'));
                this.setFirstHalfOfCurrentMonthDates();
            } else {
                this.mes = this.capitalizeFirstLetter(currentDate.format('MMMM'));
                this.setSecondHalfOfPreviousMonthDates();
            }
        },
        formatDate() {
      // Obtén la fecha actual usando Moment.js
      const currentDate = moment();

      // Formatea la fecha en el formato "DD-MM-YYYY"
      const fechaFormateada = currentDate.format('DD-MM-YYYY');

      // Asigna la fecha formateada a this.diaAc
      this.diaAc = fechaFormateada;
    },
        capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
        },
        setSecondHalfOfPreviousMonthDates() {
        const previousMonth = moment().subtract(1, 'month');
        const daysInPreviousMonth = previousMonth.daysInMonth();
        const startDate = daysInPreviousMonth / 2 + 1;
        const endDate = daysInPreviousMonth;
        this.dias=` ${startDate} - ${endDate}`
        this.mes=`${this.mes}`
        this.fechaIni = `${previousMonth.format('YYYY')}/${
            previousMonth.format('MM')
        }/${startDate}`;
        this.fechaFin = `${previousMonth.format('YYYY')}/${
            previousMonth.format('MM')
        }/${endDate}`;
        
        },
        setFirstHalfOfCurrentMonthDates() {
        const currentMonth = moment();
        const daysInCurrentMonth = currentMonth.daysInMonth();
        const endDate = Math.ceil(daysInCurrentMonth / 2);

        this.dias=`1 - ${endDate}`
        this.mes=`${this.mes}`
        this.fechaIni = `${currentMonth.format('YYYY')}/${currentMonth.format('MM')}/01`;
        this.fechaFin = `${currentMonth.format('YYYY')}/${currentMonth.format('MM')}/${endDate}`;
        
        },
        async listaAsesores() {
           await asesorService.getAsesorEsp().then(respa => {
            this.listaAse = respa
            })
            await reportesServices.felizometroAsesorReporte(this.fechaIni,this.fechaFin).then(resp=>{
                this.repote=resp
            })
            for (let asesor of this.listaAse) {
                let infoAdicional = this.repote.find(item => item.asesor === asesor.nombre);
                    if (infoAdicional) {
                        // Crear un objeto con la información requerida
                        let resultado = {
                        id: asesor.id,
                        nombre: asesor.nombre,
                        asesor: asesor.nombre,
                        calificacion: infoAdicional.calificacion,
                        };

                        // Agregar el objeto al array combinado
                        this.nameList.push(resultado);
                        
                    }
                    
            }
            this.nameList.sort((a, b) => b.calificacion - a.calificacion);
            this.largo=this.listaAse.length
        },
        cerrarModal(){
            document.querySelector('[data-target="#modalConverDash"]').click(close)
        },
        sumarF(a,b) {
            if(b==1){
                const fechaInicio = moment(a, 'YYYY-MM-DD'); // Usamos el formato YYYY-MM-DD
                if (fechaInicio.isValid()) {
                    const fechaFin = fechaInicio.clone().add(14, 'days');

                    // Formatea la fecha de fin como YYYY-MM-DD
                    a = fechaInicio.format('YYYY-MM-DD');
                    this.fecha_fin = fechaFin.format('YYYY-MM-DD');
                }
            }else{
                const fechaInicio = moment(a, 'YYYY-MM-DD'); // Usamos el formato YYYY-MM-DD
                if (fechaInicio.isValid()) {
                    const fechaFin = fechaInicio.clone().add(14, 'days');

                    // Formatea la fecha de fin como YYYY-MM-DD
                    a = fechaInicio.format('YYYY-MM-DD');
                    this.fecha_finAA = fechaFin.format('YYYY-MM-DD');
                }
            }
        },
        cerrarNota() {
            let data = {
                id: this.conve.id
            };
            asesorService.cerrarNota(data);
            this.cerrar();
            
            // Buscar el índice del elemento en el arreglo Notas basado en su valor
            const indice = this.Notas.findIndex(item => item.id === this.conve.id);
            
            if (indice !== -1) {
                // Usar Vue.delete para eliminar el elemento del arreglo Notas
                Vue.delete(this.Notas, indice);
            }
        },
        filtro() {
            let data={
                asesorid:this.asesor.id,
                fecha_ini:this.fecha_ini,
                fecha_fin:this.fecha_fin
            };
            asesorService.getNotasEsp(data).then(resp=>{
                this.NotasEsp=resp
            })
            
        },
    }
}
</script>
    