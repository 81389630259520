<template>
    <div class="rating">
      <ul class="c-rating" style="padding-top: 4px;">
        <li
          v-for="(star, index) in stars"
          :key="index"
          :class="{
            'c-rating__item': true,
            'is-active': index < rating,
            'left': index % 2 === 0
          }"
          @click="updateRating(index + 1)"
        ></li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      rating: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        stars: Array(10).fill(null) // Crear un array de 10 elementos para representar las estrellas
      };
    },
    methods: {
      updateRating(newRating) {
        // Actualizar el valor de rating según la estrella seleccionada
        this.$emit('update:rating', newRating);
      }
    }
  };
  </script>
<style>
.rating .c-rating {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    margin: 5px;
    padding: 0;
}

.rating .c-rating__item {
    -webkit-box-flex: 0;
     -webkit-flex: 0 0 12px;
    -ms-flex: 0 0 12px; 
    flex: 0 0 12px;
    height: 24px;
    background-position: -12px 0px;
    background-image: url(http://svgshare.com/i/3KS.svg);
}

.rating .c-rating__item.left {
    background-position: 0px 0px;
    margin-left: 17px;
}

.rating .c-rating__item.is-active {
    background-position: -36px 0;
}

.rating .c-rating__item.left.is-active {
    background-position: -24px 0;
}
</style>
