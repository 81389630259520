<template>
  <div class="grafica1" >
    <div  v-if="loading" style="height: 180px;  display: grid; place-items: center;">
      <span class="spinner-border spinner-border-sm " role="loading" aria-hidden="true"></span>
    </div>
    <Chart v-if="!loading" type="bar" :data="basicData" :options="options" />
  </div>
</template>

<style scoped>
</style>

<script>
import Chart from 'primevue/chart';

export default {
  components: {
    'Chart': Chart
  },
  props: ['fecha', 'numeroTotalMeta', 'numeroMiMeta'],
  data() {
    return {
      loading: true,
      basicData: '',
      options: {
        responsive: true,
        hoverMode: 'index',
        scales: {
          yAxes: [{
            stacked: false, // Cambiado a 'false' para que no se sumen las barras
            ticks: {
              beginAtZero: true,
            },
          }],
          xAxes: [{
            stacked: true,
            barThickness: 50,
            barPercentage: 0.8,
          }],
        },
        legend: {
          display: false,
        },
        tooltips: {
          enabled: true, // Desactivar tooltips al pasar el mouse
        },
      },
      };
    },
    mounted() {
      var tiempoEnMili = 6000; // 6 segundos en milisegundos
        if (this.numeroMiMeta == 0) {
          this.waitForTotalMetaUpdate().then(() => {
            setTimeout(() => {
              this.loading = false; // Oculta el efecto de carga
              this.updateChartData(); // Actualiza la gráfica después de 6 segundos
            }, tiempoEnMili);
          });
        } else {
          this.updateChartData();
      }
      var tiempoEnMilisegundos = 5000; // 30 segundos en milisegundos
      if (this.numeroMiMeta == 0) {
        this.waitForTotalMetaUpdate().then(() => {
          setTimeout(this.updateChartData, tiempoEnMilisegundos);
        });
      } else {
        this.updateChartData();
      }
    },

    watch: {
      numeroTotalMeta: {
        immediate: true,
        handler(newTotalMeta, oldTotalMeta) {
          if (newTotalMeta !== oldTotalMeta) {
            // Total meta has been updated, you can perform necessary actions here
            // For example, update the chart data or any other logic
            this.updateChartData();
          }
        }
      }
    },
    methods: {
      updateChartData() {
  this.basicData = {
    labels: [this.fecha],
    datasets: [
      {
        label: 'Pacientes Atendidos',
        data: [this.numeroMiMeta],
        backgroundColor: ['#ffcc00', 'rgba(255, 204, 0, 0.7)', 'rgba(255, 204, 0, 0.4)'],
      },
      {
        label: 'Meta Total',
        data: [this.numeroTotalMeta],
        backgroundColor: ['#63b598', 'rgba(99, 181, 152, 0.7)', 'rgba(99, 181, 152, 0.4)'],
      },
    ],
  };
},

      waitForTotalMetaUpdate() {
        return new Promise((resolve) => {
          const checkUpdate = () => {
            if (this.numeroTotalMeta !== 0) {
              resolve();
            } else {
              setTimeout(checkUpdate, 100); // Check again after a short delay
            }
          };
          checkUpdate();
        });
      }
    },
  };
  </script>
  