<template>
    <div :class="{' barraNavegacionAsesor': asesor, 'fix barraNavegacion': !asesor}">
            <ul class="pl-0">
                <li v-show="asesor" class=" elementoMenuA ">
                    <font-awesome-icon icon="bars" />
                </li>
                <li v-show="!asesor">
                    <div class="cambiarAncho"><div class="flechaAncho" onclick="document.documentElement.classList.toggle('menuCambiado')"><font-awesome-icon icon="angle-left" /></div></div>
                </li>
                <li v-if="showInicio()">
                    <router-link v-show="asesor" :to="{name:'dashboardAsesor'}"><div id="elemento0" class="elementoMenu2 "><font-awesome-icon icon="home"/><b class="pl-1 desaparece">Inicio</b></div></router-link>
                    <router-link v-show="!asesor" :to="{name:'resumen'}"><div id="elemento0" class="elementoMenu menuActivo"><font-awesome-icon icon="home"/><b class="pl-1 desaparece">Inicio</b></div></router-link>
                </li>
                <li v-if="showReportes()">
                    <router-link :to="{name:'reportes'}"><div id="elemento1" class="elementoMenu menuActivo" :class="{'d-none': sessionStorageUser.rol_id == (4,3)}"><font-awesome-icon icon="chart-line"/><b class="pl-1 desaparece">Reportes</b></div></router-link>
                </li>
                <li  v-if="showHistorial()">
                    <router-link :to="{name:'historial'}"><div id="elemento2" class="elementoMenu menuActivo" :class="{'d-none': sessionStorageUser.rol_id ==(4,3)}"><font-awesome-icon icon="history"/><b class="pl-1 desaparece">Historial</b></div></router-link>
                </li>
                <li v-if="showParametros()">
                    <router-link :to="{name:'parametros'}"><div id="elemento6" class="elementoMenu menuActivo" :class="{'d-none': sessionStorageUser.rol_id ==(4,3)}"><font-awesome-icon icon="wrench"/><b class="pl-2 desaparece">Parámetros</b></div></router-link>
                </li> 
                 <!-- || sessionStorageUser.id==(14)||(21) -->
                <li v-if="(sessionStorageUser.rol_id == (1) || (sessionStorageUser.id == 21 || sessionStorageUser.id == 235 || sessionStorageUser.id == 110 || sessionStorageUser.id == 47|| sessionStorageUser.id == 46|| sessionStorageUser.id == 106|| sessionStorageUser.id == 256)) && (sessionStorageUser.grupo_id!=6 && sessionStorageUser.grupo_id!=10  )">
                    <router-link :to="{name:'servicecontrol'}"><div id="elemento6" class="elementoMenu menuActivo" :class="{'d-none': sessionStorageUser.rol_id ==(4,3)}"><font-awesome-icon icon="sliders-h"/><b class="pl-2 desaparece">Controles</b></div></router-link>
                </li>
                <li v-if="showInbox()" @click="borraSender">
                    <router-link v-show="!asesor" :to="'/chat'"><div id="elemento5" class="elementoMenu bg-primary d-none d-sm-none d-md-block"><font-awesome-icon icon="comment-dots"/><b class="pl-1 desaparece">Inbox</b></div></router-link>
                </li>
            </ul>
        </div>
</template>
<style scoped>
.cambiarAncho{
    background-color: #00000000;
    bottom: -10px;
}
.barraNavegacionAsesor{
background: #818285;
opacity: 1; 
top: 0px;
left: 0px;
width: 103px;
height: 100%;
position:fixed
}
.elementoMenu2{
top: 76px;
left: 36px;
width: 30px;
height: 26px;
right: 36px;
position: absolute;
font-size: 30px;
/* UI Properties */
color: #EBEBEB;
opacity: 1;
}
.elementoMenuA{
top: 23px;
    position: absolute;
    font-size: 33px;
    color: #EBEBEB;
    opacity: 1;
    left: 37px;
    list-style: none; 
}
</style>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars,faAngleLeft, faCommentDots, faChartLine, faHistory, faPaperPlane, faTable, faEye, faExclamationTriangle, faWrench, faViruses,faSlidersH,faRibbon, faFileMedicalAlt,faNewspaper } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faBars,faAngleLeft, faCommentDots, faChartLine, faHistory, faPaperPlane, faTable, faEye, faExclamationTriangle, faWrench, faViruses,faSlidersH,faRibbon, faFileMedicalAlt,faNewspaper)
export default {
    name: 'Menu',
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    data() {
        return {
            sessionStorageUser: JSON.parse(sessionStorage.getItem('sesion')),
            ruta: window.location.pathname,
            asesor: false,
            excludedGroups: [6, 9, 10]
    };
    },
    created(){
        document.documentElement.classList.toggle('menuCambiado')
        if(this.sessionStorageUser.rol_id==3){
            this.asesor=true
        }
    },
    methods: {
        showInicio() {
            const { rol_id, grupo_id } = this.sessionStorageUser;
            const isRolValid = (rol_id === 1 || rol_id === 2|| rol_id === 4);
            const isGroupExcluded = ![6, 9, 10].includes(grupo_id);
            return isRolValid && isGroupExcluded;
        },
        showReportes() {
            const { rol_id } = this.sessionStorageUser;
            return (rol_id === 1 || rol_id === 2);
        },
        showHistorial() {
            const { rol_id, grupo_id } = this.sessionStorageUser;
            const isRolValid = (rol_id === 1 || rol_id === 2);
            const isGroupExcluded = ![9, 10].includes(grupo_id);
            return isRolValid && isGroupExcluded;
        },
        showParametros() {
            const { rol_id, grupo_id } = this.sessionStorageUser;

           if (grupo_id === 10 ) {
                const isRolValid = (rol_id === 1);
                return isRolValid;
            } else {
                const isRolValid = (rol_id === 1 || rol_id === 2); 
                return isRolValid  
            }      
        },
           showControles() {
            const { rol_id, grupo_id, id } = this.sessionStorageUser;
            const validIds = [21, 235, 110, 47, 46, 106];
            const isRolValid = (rol_id === 1 || validIds.includes(id));
            const isGroupExcluded = ![6, 10].includes(grupo_id);
            return isRolValid && isGroupExcluded;
        },
         showInbox() {
            const { rol_id } = this.sessionStorageUser;
            const isRolValid = (rol_id === 1 || rol_id === 2 || rol_id === 4);
            return isRolValid; // Solo se muestra si el rol es 1 o 2, independientemente del grupo_id
        },
        borraSender(){
            sessionStorage.removeItem('seleccionChat')
            sessionStorage.removeItem('senderid')
            sessionStorage.removeItem('sender_actual')
        }
    },
}
</script>